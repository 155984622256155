//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import Storage from "@/plugins/Storage";
import { mdiTranslate } from "@quasar/extras/mdi-v4";

export default {
  name: "Auth",
  data() {
    return {
      mdiTranslate: "",
      langs: [
        {
          label: "English",
          value: "en_US",
        },
        {
          label: "Nederlands",
          value: "nl_NL",
        },
        {
          label: "中文",
          value: "zh_CN",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      darkTheme: "getDarkTheme",
    }),
    showLogo() {
      return this.$route.name !== "index";
    },
  },
  methods: {
    homePage() {
      this.$router.push("/welcome").catch(() => {});
    },
    changeLanguage(val) {
      this.$i18n.locale = val;
    },
    updateDarkTheme() {
      try {
        if (this.darkTheme == true) {
          document.querySelector("body").classList.remove("dark");
          Storage.setItem("theme", "white");
          this.$q.dark.set(false);
        } else {
          const { body } = document;
          body.classList.add("dark");
          Storage.setItem("theme", "dark");
          this.$q.dark.set(true);
        }
        this.$store.commit("SET_DARK_THEME", !this.darkTheme);
      } catch {
        Storage.setItem("theme", !this.darkTheme);
        location.reload();
      }
    },
  },
  watch: {},
  beforeMount() {
    let theme = Storage.getItem("theme");
    if (theme == "dark") {
      const { body } = document;
      body.classList.add("dark");
      this.$q.dark.set(true);
      this.$store.commit("SET_DARK_THEME", true);
    } else {
      document.querySelector("body").classList.remove("dark");
      this.$q.dark.set(false);
      this.$store.commit("SET_DARK_THEME", false);
    }
  },
  created() {
    this.mdiTranslate = mdiTranslate;
  },
};
